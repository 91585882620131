import { i18n } from 'app/utils/i18n';
import { useEditorSelector } from 'app/state/contexts/EditorContext';
import { useComments } from 'app/api/useComments';
import { useMemo, useState, useContext, useEffect } from 'react';
import { serialize } from 'app/slate/compareUtils';
import { SidebarContext } from 'app/state/contexts/SidebarContext';
import { useSlate } from 'slate-react';
import { Comment } from './Comment';
import { flatMap } from 'lodash';
import moment from 'moment';

export const Comments = () => {
  const { superId: sectionSuperId, content } = useEditorSelector((editor) => editor.section);
  const [activeResponse, setActiveResponse] = useState(null);
  const [includeClosedComments, setIncludeClosedComments] = useState(false);
  const { comments, isCommentsLoading } = useComments(sectionSuperId);
  const { draftComment, setActiveComment } = useContext(SidebarContext);

  const editor = useSlate();

  const reorderedComments = useMemo(() => {
    let flatDocument = {};
    if (content && Object.keys(content).length !== 0) {
      flatDocument = serialize({ content });
    }
    if (isCommentsLoading) {
      return [];
    }

    let orderedComments = flatMap(
      Object.entries(flatDocument).map(([key, value]) => comments.filter((comment) => comment.onId === key && comment.onType !== 'comment'))
    );

    if (!includeClosedComments) {
      orderedComments = orderedComments.filter((comment) => !comment?.closedAt);
    }

    const commentsWithResponses = orderedComments.map((comment) => {
      const responses =
        comments
          .filter((response) => response.onType === 'comment' && response.onId === comment.id)
          .sort((a, b) => moment(b.createdAt) - moment(a.createdAt)) || [];
      return { ...comment, responses };
    });
    return commentsWithResponses;
  }, [content, isCommentsLoading, comments, includeClosedComments]);

  useEffect(() => {
    if (draftComment) {
      setActiveComment(draftComment);
    }
    return () => {
      setActiveComment(null);
      setActiveResponse(null);
    };
  }, [editor, draftComment, setActiveComment]);
  const noComments = reorderedComments.length === 0 && !isCommentsLoading && !draftComment;
  return (
    <div className="preview--container h-100">
      <div className="px-3 pt-4 pb-3 w-100 bg-white sticky-top">
        <div className="fw-bold">{i18n('doc-editor-link.sidebar.comments-tooltip')}</div>
        <div className="comment--list--filter py-3">
          <p>{i18n('doc-editor-link.sidebar.comment.show-closed')}</p>
          <div className="form-switch">
            <input
              className="form-check-input"
              value={includeClosedComments}
              onChange={(e) => setIncludeClosedComments(!includeClosedComments)}
              type="checkbox"
              role="switch"
            />
          </div>
        </div>
      </div>
      <div className={`comment--list ${noComments ? 'no-comments' : ''}`}>
        {noComments && (
          <div className="" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <i className="fa-regular fa-message-xmark" />
            {i18n('doc-editor-link.sidebar.no-comments')}
          </div>
        )}
        {draftComment && <Comment comment={draftComment} key={draftComment.id} />}
        {isCommentsLoading ? (
          <div>{i18n('common.loading-message')}</div>
        ) : (
          reorderedComments.map((comment) => (
            <Comment key={comment.id} comment={comment} responses={comment.responses} activeResponse={activeResponse} setActiveResponse={setActiveResponse} />
          ))
        )}
      </div>
    </div>
  );
};
