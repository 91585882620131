import { i18n } from '../../../../utils/i18n';
import { navigateTo } from 'app/slate/utils';
import { useSlate } from 'slate-react';
import { useContext } from 'react';
import { EditorContext, useEditorSelector } from 'app/state/contexts/EditorContext';
import { removeBookmark, getBookmarkById } from 'app/slate/extensions/bookmark';

import { useBookmarks } from 'app/utils/hooks/useBookmarks';
import Tippy from '@tippyjs/react';
import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { getLinkById, removeLink } from 'app/slate/extensions/link';

const BookmarkList = () => {
  const editor = useSlate();
  const { shadowHost } = useContext(EditorContext);
  const { documentSuperId, superId: sectionId } = useEditorSelector((editor) => editor.section);
  const { bookmarkAndLinks, isBookmarksAndLinksLoading, deleteBookmark, deleteLink } = useBookmarks(documentSuperId);
  const handleRemoval = async (id, type) => {
    switch (type) {
      case 'bookmark':
        deleteBookmark({ id, documentSuperId });
        removeBookmark(id, editor);
        break;
      case 'link':
        deleteLink({ id, documentSuperId });
        removeLink(id, editor);
        break;
      default:
        console.error('Wrong type when deleting, please use bookmark or link');
    }
  };
  const handleNavigation = (elementId, type) => {
    const found = (type === 'bookmark' ? getBookmarkById : getLinkById)(editor, elementId).found;

    if (found) {
      const [, textPath] = Editor.node(editor, found.path);
      const range = Editor.range(editor, textPath);
      Transforms.select(editor, range);
      ReactEditor.focus(editor);
    }
    navigateTo(shadowHost, elementId);
  };
  if (isBookmarksAndLinksLoading) return <div>{i18n('common.loading-message')}</div>;
  return (
    <>
      <div className="bookmark-sidebar">
        {bookmarkAndLinks.map((bookmarkAndLink, index) => {
          const isBookmarkInSameSection = bookmarkAndLink.sectionId === sectionId;
          return (
            <div key={index} className="bookmark-sidebar-content">
              <div className="bookmark-sidebar-bookmark-content">
                <Tippy
                  content={
                    <div className="bg-gray-100 p-2 rounded">
                      {!isBookmarkInSameSection
                        ? bookmarkAndLink.sectionName
                        : bookmarkAndLink.links.length !== 0
                        ? i18n('bookmarks.sidebar.remove-connected-links')
                        : ''}
                    </div>
                  }
                  disabled={isBookmarkInSameSection && bookmarkAndLink.links.length === 0}
                >
                  <i
                    className={`fa-solid fa-trash ${(!isBookmarkInSameSection || bookmarkAndLink.links.length !== 0) && 'link-bookmark-disabled'}`}
                    onClick={() => isBookmarkInSameSection && bookmarkAndLink.links.length === 0 && handleRemoval(bookmarkAndLink.bookmarkId, 'bookmark')}
                  />
                </Tippy>
                <i
                  className={`fa-regular fa-bookmark ${!isBookmarkInSameSection && 'link-bookmark-disabled'}`}
                  onClick={() => {
                    isBookmarkInSameSection && handleNavigation(bookmarkAndLink.bookmarkId, 'bookmark');
                  }}
                />
                <p>{bookmarkAndLink.label}</p>
              </div>
              <i className="fa-regular fa-arrow-right bookmark-sidebar-content-icon" />
              <div className="bookmark-sidebar-link-content-wrapper" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexGrow: 1 }}>
                {bookmarkAndLink.links.length === 0 ? (
                  <p className="bookmark-sidebar-link-content-inner">{i18n('bookmarks.sidebar.no-links')} </p>
                ) : (
                  bookmarkAndLink.links.map((link) => {
                    const isLinkInSameSection = link.sectionId === sectionId;
                    return (
                      <div key={link.id} className="bookmark-sidebar-link-content-inner">
                        <p>{link.label}</p>
                        <i
                          className={`fa-regular fa-link ${!isLinkInSameSection && 'link-bookmark-disabled'}`}
                          onClick={() => {
                            isLinkInSameSection && handleNavigation(link.linkId, 'link');
                          }}
                        />
                        <Tippy content={<div className="bg-gray-100 p-2 rounded">{link.sectionName}</div>} disabled={isLinkInSameSection}>
                          <i
                            className={`fa-solid fa-trash ${!isLinkInSameSection && 'link-bookmark-disabled'}`}
                            onClick={() => isLinkInSameSection && handleRemoval(link.linkId, 'link')}
                          />
                        </Tippy>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="divider" />
    </>
  );
};

export const Bookmarks = ({ items }) => {
  return (
    <div className="preview--container">
      <div className="px-3 pt-4 pb-3 w-100 bg-white sticky-top">
        <div className="fw-bold">{i18n('bookmarks.sidebar.title')}</div>
      </div>
      <div className="px-3 w-100">
        <BookmarkList />
      </div>
    </div>
  );
};
