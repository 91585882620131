import { Element, Transforms, Range, Editor } from 'slate';

const isHighlight = (element) => Element.isElement(element) && element.type === 'highlight';

const withHighlight =
  (options = {}) =>
  (editor) => {
    const { isInline, renderElement, normalizeNode } = editor;
    editor.isInline = (element) => isHighlight(element) || isInline(element);

    editor.renderElement = (props) => {
      if (isHighlight(props.element)) {
        return <HighlightElement {...props} />;
      } else {
        return renderElement(props);
      }
    };

    editor.normalizeNode = (entry) => {
      const { node, path } = entry;
      if (Element.isElement(node) && node.type === 'highlight') {
        if (node.highlighted) {
          Transforms.setNodes(editor, { highlighted: false }, { at: path });
        }
      }
      normalizeNode(entry);
    };
    return editor;
  };

export const HighlightElement = ({ attributes, children, element }) => {
  return (
    <span {...attributes} id={element.id} className={element.highlighted ? 'layout-leaf-comment' : ''}>
      {children}
    </span>
  );
};

export const insertHighLight = async (editor, commentId) => {
  if (!editor.selection || Range.isCollapsed(editor.selection)) return;
  const highlight = {
    type: 'highlight',
    children: [],
    highlighted: true,
    id: commentId,
  };

  Transforms.wrapNodes(editor, highlight, { split: true });
};

export const getAllHighlights = (editor) => {
  const highLights = Array.from(
    Editor.nodes(editor, {
      at: [],
      match: (n) => Element.isElement(n) && n.type === 'highlight',
    })
  );

  return highLights.map(([node, path]) => ({
    node,
    path,
  }));
};

export const toggleHighlight = (editor, commentId) => {
  const highlights = getAllHighlights(editor);
  highlights.forEach(({ node, path }) => {
    if (node.id === commentId) {
      Transforms.setNodes(editor, { highlighted: true }, { at: path });
    } else {
      Transforms.setNodes(editor, { highlighted: false }, { at: path });
    }
  });
};

export default withHighlight;
