import { StyledElement } from 'app/slate/components/StyledElement';
import { refHandler } from 'app/utils/helper-functions';
import { useState, useMemo } from 'react';
import { AddComment } from 'app/components/editor/sidebar/comments/AddComment';
import { v4 as uuidv4 } from 'uuid';
import { BookmarkElement } from '../extensions/bookmark';
import { LinkElement } from '../extensions/link';
import { StyledParagraphElement } from '../extensions/styled-paragraph';
import { HighlightElement } from '../extensions/highlight';

export const Element = (props) => {
  const { attributes, children, element, withComments } = props;
  const [ref, setRef] = useState(null);
  const elementUUID = useMemo(() => element.uuid || uuidv4(), [element.uuid]);
  const anchor = `anchor-${elementUUID}`;
  const listId = `xrp-list-${elementUUID}`;

  // Add id to attributes if it does not exist
  const safeAttributesWithId = (attrs) => ({
    ...attrs,
    id: attrs.id || listId,
  });
  switch (element.type) {
    case 'table':
      return (
        <table>
          <tbody style={{ userSelect: 'none' }} contentEditable={false} {...attributes}>
            {children}
          </tbody>
        </table>
      );
    case 'bulleted-list':
      return <ul {...safeAttributesWithId(attributes)}>{children}</ul>;
    case 'numbered-list':
      return <ol {...safeAttributesWithId(attributes)}>{children}</ol>;
    case 'list-item':
      return (
        <li {...safeAttributesWithId(attributes)} ref={refHandler(attributes, setRef)}>
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <a contentEditable="false" href={`#${anchor}`} id={anchor}></a>
          {!!withComments && ref ? <AddComment paragraph={ref} element={element} /> : null}
          {children}
        </li>
      );
    case 'table-row':
      return <tr {...attributes}>{children}</tr>;
    case 'table-cell':
      return <td {...attributes}>{children}</td>;
    case 'paragraph':
      return <StyledElement {...props} />;
    case 'custom-formatting':
      return <StyledElement {...props} />;
    case 'bookmark':
      return <BookmarkElement {...props} />;
    case 'styled-paragraph':
      return <StyledParagraphElement {...props} />;
    case 'link':
      return <LinkElement {...props} />;
    case 'highlight':
      return <HighlightElement {...props} />;
    default:
      console.warn('No explicit type for render in editor found, using default element', props);
      return (
        <p className="default-editor-element" {...attributes}>
          {children}
        </p>
      );
  }
};
