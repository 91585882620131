import { tryCatch } from 'ramda';
import { Editor as SlateEditor, Element, Transforms } from 'slate';
import { v4 as uuidv4 } from 'uuid';
import { hasText } from 'app/utils/helper-functions';
import { Paragraph } from 'app/slate/components/Paragraph';

export const isParagraph = (node) => Element.isElement(node) && node.type === 'paragraph';
export const isCustomFormatting = (node) => Element.isElement(node) && node.type === 'custom-formatting';

export const defaultParagraph = (revisionId) => ({
  type: 'paragraph',
  uuid: uuidv4(),
  revisionId,
  children: [{ text: '' }],
});

const paragraph =
  (options = {}) =>
  (editor) => {
    const withComments = options.withComments ?? false;
    const withStyledParagrah = false;
    const { insertBreak, normalizeNode, renderElement, deleteBackward } = editor;

    editor.deleteBackward = (unit) => {
      if (!editor.selection) {
        deleteBackward(unit);
        return;
      }

      // Default behaviour if in middle of text
      if (editor.selection.focus.offset !== 0) {
        deleteBackward(unit);
        return;
      }

      const currentParagraph = SlateEditor.above(editor, {
        at: editor.selection.anchor.path,
        match: isParagraph || isCustomFormatting,
      });
      if (!currentParagraph) {
        deleteBackward(unit);
        return;
      }

      const before = SlateEditor.before(editor, editor.selection.anchor.path);
      if (!before) {
        return;
      }

      deleteBackward(unit);
    };

    editor.normalizeNode = (entry) => {
      const [node, path] = entry;

      // Only deal with paragraphs without uuid
      if (!isParagraph(node) && !isCustomFormatting(node)) {
        normalizeNode(entry);
        return;
      }
      if (!node.uuid) {
        Transforms.setNodes(
          editor,
          {
            uuid: uuidv4(),
          },
          {
            at: path,
          }
        );
        return;
      }

      if (path[path.length - 1] !== 0 && !hasText(node)) {
        const prevPath = path.slice();
        prevPath[prevPath.length - 1]--;
        if (!hasText(SlateEditor.node(editor, prevPath)[0])) {
          Transforms.removeNodes(editor, {
            at: path,
            match: (_node) => _node === node,
          });
          return;
        }

        const nextPath = path.slice();
        nextPath[nextPath.length - 1]++;
        const nextNode = tryCatch(
          () => SlateEditor.node(editor, nextPath)?.[0],
          () => undefined
        )();
        if (nextNode && !hasText(nextNode)) {
          Transforms.removeNodes(editor, {
            at: nextPath,
            match: (_node) => _node === nextNode,
          });
          return;
        }
      }
      // If no modifications were made, pass on the torch
      normalizeNode(entry);
    };

    editor.insertBreak = () => {
      SlateEditor.withoutNormalizing(editor, () => {
        insertBreak();
        Transforms.setNodes(
          editor,
          {
            uuid: uuidv4(),
          },
          {
            at: editor.selection.anchor,
            match: (node) => node.type === 'paragraph',
          }
        );
      });
    };

    editor.renderElement = (props) => {
      if (isParagraph(props.element) || isCustomFormatting(props.element)) {
        return <Paragraph {...props} withComments={withComments} withStyledParagrah={withStyledParagrah} />;
      } else {
        props.withComments = withComments;
        return renderElement(props);
      }
    };

    return editor;
  };

export default paragraph;
