import { useState, useEffect, useContext } from 'react';
import { useEditorSelector } from 'app/state/contexts/EditorContext';
import { SidebarContext } from 'app/state/contexts/SidebarContext';
import { v4 as uuidv4 } from 'uuid';
import { UserContext } from 'app/state/contexts';

export const AddComment = ({ element }) => {
  const { user } = useContext(UserContext);

  const { superId: sectionSuperId } = useEditorSelector((editor) => editor.section);
  const selectionId = useEditorSelector((editor) => editor.selectionId);
  const [show, setShow] = useState(true);

  const { setDraftComment, setIsSidebarOpen } = useContext(SidebarContext);
  const handleClick = (e) => {
    e.preventDefault();
    const citation = window.getSelection().toString() || null;
    const commentId = uuidv4();
    const newComment = {
      id: commentId,
      sectionSuperId: sectionSuperId,
      onType: element.type,
      onId: element.uuid,
      message: '',
      userId: user.id,
      citation,
      closedAt: null,
      createdAt: new Date(),
      updatedAt: null,
      publishedAt: null,
      responses: [],
      user: {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      },
    };
    setDraftComment(newComment);
    setIsSidebarOpen(4);
  };

  useEffect(() => {
    setShow(selectionId && selectionId === element.uuid);
  }, [element.uuid, selectionId]);

  if (!show) {
    return null;
  }

  return (
    <div className="comment--add-coment" onMouseDown={handleClick}>
      <i className="fa-regular fa-message-plus" />
    </div>
  );
};
