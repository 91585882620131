import { useState, createContext } from 'react';
export const SidebarContext = createContext();

export const SidebarContextProvider = ({ children }) => {
  const [styledParagraph, setStyledParagraph] = useState(null);
  const [draftComment, setDraftComment] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(1);
  const [activeComment, setActiveComment] = useState(null);
  return (
    <SidebarContext.Provider
      value={{
        draftComment,
        setDraftComment,
        styledParagraph,
        setStyledParagraph,
        isSidebarOpen,
        setIsSidebarOpen,
        activeComment,
        setActiveComment,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
