import { CommentHeader } from './CommentHeader';
import { MentionInput } from '../../../MentionInput';
import { useState, useRef, useContext } from 'react';
import { useComments } from 'app/api/useComments';
import { SidebarContext } from 'app/state/contexts/SidebarContext';
import { useDelayedSave } from 'app/utils/hooks/delayed-save';
import { useEditorDispatch } from 'app/state/contexts/EditorContext';
import { setIsDirty } from 'app/state/redux/documentSlice';
import { i18n } from 'app/utils/i18n';

const formatMessage = (text) => {
  const parts = text.split(/(@\w+)/g);
  return parts.map((part, index) =>
    part.startsWith('@') ? (
      <span key={index} className="comment--mention">
        {part}
      </span>
    ) : (
      part
    )
  );
};

export const Response = ({ response, activeResponse, setActiveResponse, sectionSuperId, setNewResponseComment }) => {
  const [responseComment, setResponseComment] = useState(response);
  const responseRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { updateCommentStatus, updateCommentMessageAsync, createCommentAsync, publishComment, deleteComment, refetchComments } = useComments(sectionSuperId);
  const { setDraftComment } = useContext(SidebarContext);
  const editorDispatch = useEditorDispatch();
  const saveResponse = async (message) => {
    editorDispatch(setIsDirty(true));
    if (responseComment?.publishedAt) {
      await updateCommentMessageAsync({ commentId: responseComment.id, message: responseComment.message });
    } else {
      const newResponseComment = { ...responseComment, message: responseComment.message, publishedAt: null };
      await createCommentAsync({ sectionSuperId, comment: newResponseComment });
    }
    editorDispatch(setIsDirty(false));
  };
  const [save, cancel] = useDelayedSave((message) => saveResponse(message), 1500);

  const handleChange = (e) => {
    cancel();
    setResponseComment({ ...responseComment, message: e });
    save(e);
  };

  const handleResponseSubmit = async (e) => {
    cancel();
    e.preventDefault();
    await saveResponse(responseComment.message);
    publishComment({ commentId: responseComment.id, publish: true });
    setIsUpdating(false);
    setDraftComment(null);
    setActiveResponse(null);
    refetchComments();
    setNewResponseComment();
  };

  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDraftComment(null);
    setIsUpdating(false);

    setActiveResponse(null);
  };

  const actionHandler = (action, event) => {
    event.stopPropagation();
    switch (action) {
      case 'edit':
        setIsUpdating(true);
        break;
      case 'delete':
        deleteComment(responseComment.id);
        break;
      case 'open':
      case 'close':
        updateCommentStatus({ commentId: responseComment.id, status: action !== 'open' });
        break;
      default:
        return;
    }
    setIsMenuOpen(false);
  };

  return (
    <div className={`${activeResponse ? 'active' : ''} ${!response?.publishedAt ? 'draft' : ''} ${response?.closedAt ? 'archived' : ''} comment`}>
      <CommentHeader
        isActiveComment={activeResponse}
        comment={response}
        isUpdating={isUpdating}
        setMenuOpen={setIsMenuOpen}
        menuOpen={isMenuOpen}
        actionHandler={actionHandler}
      />
      {isUpdating || !responseComment?.updatedAt ? (
        <>
          <MentionInput value={responseComment?.message ?? ''} onChange={handleChange} ref={responseRef} placeHolder={i18n('comments.response.placeholder')} />
          <div className="comment--buttons">
            <button onClick={handleCancel} className="btn btn-ghost btn-md">
              {i18n('common.button.cancel')}
            </button>
            <button
              onClick={async (e) => {
                cancel();
                await saveResponse(responseComment.message);
                publishComment({ commentId: responseComment.id, publish: false });
                setDraftComment(null);
                setIsUpdating(false);
                refetchComments();
                setNewResponseComment();
              }}
              className="btn btn-secondary btn-md"
            >
              {i18n('common.save-draft')}
            </button>
            <button onClick={handleResponseSubmit} className="btn btn-primary btn-md">
              {i18n('common.publish')}
            </button>
          </div>
        </>
      ) : (
        <div className="responsecomment--message">{formatMessage(responseComment?.message)}</div>
      )}
    </div>
  );
};
