import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import request from 'app/api/request';
import Navbar from 'app/components/editor/navbar/Navbar';
import Sidebar from 'app/components/editor/sidebar/Sidebar';
import { Editor } from 'app/components/editor/Editor';
import withMentions from 'app/slate/extensions/mentions';
import withImportArea from 'app/slate/extensions/import-area';
import { SidebarContextProvider } from 'app/state/contexts/SidebarContext';
import withNotes from 'app/slate/extensions/notes';
import withPage from 'app/slate/extensions/page';
import withParagraph from 'app/slate/extensions/paragraph';
import withLayout from 'app/slate/extensions/layout';
import withImage from 'app/slate/extensions/image';
import { generateDiffState } from 'app/slate/compareUtils';
import { DiffContext } from 'app/state/contexts/DiffContext';
import withCompare from 'app/slate/extensions/compare';
import VersionSelector from './VersionSelector';
import ServerErrorHandler from 'app/ErrorHandler';
import { SupportModal } from 'app/components';
import withReferenceTable from 'app/slate/extensions/reference-table';
import { setSection } from 'app/state/redux/documentSlice';
import { Spinner } from 'app/components';
import { useEditorDispatch } from 'app/state/contexts/EditorContext';
import withTableOfContent from 'app/slate/extensions/table-of-content';
import { v4 as uuidv4 } from 'uuid';
import { useRevisionL, useRevisionR } from 'app/components/editor/sidebar/useRevision';
import { useEditorSelector } from 'app/state/contexts/EditorContext';

const defaultDiffState = { left: {}, right: {}, leftSource: {}, rightSource: {} };

const extensionsL = [
  withLayout(),
  withCompare({ side: 'left', readOnly: true }),
  withMentions,
  withImportArea({ readOnly: true }),
  withImage({ readOnly: true }),
  withPage({ withButtons: false, readOnly: true }),
  withNotes,
  withParagraph({ withComments: false }),
  withReferenceTable({ readOnly: true }),
  withTableOfContent({ readOnly: true }),
];

const extensionsR = [
  withLayout({ markEmpty: true }),
  withCompare({ side: 'right', readOnly: true }),
  withMentions,
  withImportArea({ readOnly: true }),
  withImage({ readOnly: true }),
  withPage({ withButtons: true, readOnly: true }),
  withNotes,
  withParagraph({ withComments: true }),
  withReferenceTable({ readOnly: true }),
  withTableOfContent({ readOnly: true }),
];

const getDocumentRevision = (documentRevisionId, documentId) => {
  if (documentRevisionId) {
    return request.get(`/gaby/documents/${documentRevisionId}?compileSass=true`).then((res) => res.data);
  } else {
    return request
      .get(`/gaby/documents?superId=${documentId}&current=true&compileSass=true`)
      .then((res) => res.data)
      .then((documents) => {
        if (documents) {
          return documents[0];
        }
      });
  }
};

const getSectionRevision = (document, sectionSuperId) => {
  const sectionRevisionId = document.content.sections.find((section) => section.superId === sectionSuperId)?.revisionId;
  return request.get(`/gaby/sections/${sectionRevisionId}`).then((res) => res.data);
};

const revisionIdOrCurrent = (revisionId) => {
  return revisionId === 'current-draft' ? '' : revisionId;
};

const SideBySideViewer = () => {
  const { documentId, sectionId } = useParams();
  const [revisionIdLeft, setRevisionIdLeft] = useRevisionL();
  const [revisionIdRight, setRevisionIdRight] = useRevisionR();
  const referencesL = useEditorSelector((editor) => editor?.references, 'diff');
  const referencesR = useEditorSelector((editor) => editor.references);

  const [diffState, setDiffState] = useState(defaultDiffState);
  const [documentR, setDocumentR] = useState({});
  const [documentL, setDocumentL] = useState({});
  const [sectionL, setSectionL] = useState({});
  const [sectionR, setSectionR] = useState({});
  const [versions, setVersions] = useState([]);
  const [loadingL, setLoadingL] = useState(false);
  const [loadingR, setLoadingR] = useState(false);

  const editorDispatch = useEditorDispatch();

  const handleChangeVersionL = (value) => {
    setDiffState(defaultDiffState);
    setRevisionIdLeft(revisionIdOrCurrent(value));
  };

  const handleChangeVersionR = (value) => {
    setDiffState(defaultDiffState);
    setRevisionIdRight(revisionIdOrCurrent(value));
  };

  useEffect(() => {
    request
      .get(`/gaby/versions/${documentId}`)
      .then((res) => res.data)
      .then((versions) => {
        if (versions) {
          setVersions(versions);
        }
      })
      .catch(ServerErrorHandler);
  }, [documentId, sectionId, revisionIdLeft]);

  useEffect(() => {
    getDocumentRevision(revisionIdLeft, documentId)
      .then((documentL) => {
        setLoadingL(true);
        setDocumentL(documentL);
        return getSectionRevision(documentL, sectionId);
      })
      .then((sectionRevisionL) => {
        setSectionL(sectionRevisionL);
        setLoadingL(false);
      });
  }, [documentId, sectionId, revisionIdLeft]);

  useEffect(() => {
    getDocumentRevision(revisionIdRight, documentId)
      .then((documentR) => {
        setLoadingR(true);
        setDocumentR(documentR);
        return getSectionRevision(documentR, sectionId);
      })
      .then((sectionRevisionR) => {
        setSectionR(sectionRevisionR);
        editorDispatch(setSection(sectionRevisionR));
        setLoadingR(false);
      });
  }, [editorDispatch, documentId, sectionId, revisionIdRight]);

  useEffect(() => {
    const contentL = sectionL.content?.length ? sectionL.content : fallback;
    const contentR = sectionR.content?.length ? sectionR.content : fallback;
    setDiffState(generateDiffState({ content: contentL, references: referencesL }, { content: contentR, references: referencesR }));
  }, [sectionL.content, sectionR.content, sectionR.contentreferencesL, referencesL, referencesR]);

  const instanceL = { documentId, documentRevisionId: documentL.revisionId, sectionId, sectionRevisionId: sectionL.revisionId };
  const instanceR = { documentId, documentRevisionId: documentR.revisionId, sectionId, sectionRevisionId: sectionR.revisionId };

  return (
    <SidebarContextProvider>
      <DiffContext.Provider value={{ diffState, setDiffState }}>
        <div className="side-by-side mx-3">
          <div className="my-3">
            <div className="top"></div>
            {loadingL ? (
              <div>
                <Spinner />
              </div>
            ) : (
              <Editor showDiff editorId="diff" readOnly={true} extensions={extensionsL} instance={instanceL}>
                <div className="section-name fw-bold">{sectionL.name ? sectionL.name : ''}</div>
              </Editor>
            )}
          </div>
          <div className="my-3">
            <div className="top"></div>
            {loadingR ? (
              <div>
                <Spinner />
              </div>
            ) : (
              <Editor showDiff readOnly={true} extensions={extensionsR} instance={instanceR}>
                <div className="section-name fw-bold">{sectionR.name ? sectionR.name : ''}</div>
                <Navbar document={documentR} showXliff={false} prevLink={`/documents/${documentId}/sections/${sectionId}`} />
                <VersionSelector
                  versions={versions}
                  documentId={documentId}
                  handleChangeVersionL={handleChangeVersionL}
                  revisionIdLeft={revisionIdLeft}
                  documentL={documentL}
                  handleChangeVersionR={handleChangeVersionR}
                  revisionIdRight={revisionIdRight}
                  documentR={documentR}
                />
                <Sidebar isSideBySide={true} setRevisionL={handleChangeVersionL} />
                <SupportModal />
              </Editor>
            )}
          </div>
        </div>
      </DiffContext.Provider>
    </SidebarContextProvider>
  );
};

const fallback = [
  {
    type: 'page',
    uuid: uuidv4(),
    children: [
      {
        type: 'layout',
        children: [
          {
            type: 'paragraph',
            children: [
              {
                text: 'Motsvarande sektion saknas i den här versionen',
              },
            ],
          },
        ],
      },
    ],
  },
];

export default SideBySideViewer;
