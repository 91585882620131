import React, { useState, useEffect, useContext, useRef } from 'react';
import { UserContext } from 'app/state/contexts/UserContext';
import request from 'app/api/request';
import { Spinner } from 'app/components';
import Prism from 'prismjs';
import { v4 as uuidv4 } from 'uuid';
import 'prismjs/themes/prism.css';
import ServerErrorHandler from 'app/ErrorHandler';

const actions = {
  DOCUMENT_ACTION: 'update_document_style',
  TEMPLATE_ACTION: 'update_template_style',
};

const fetchStyles = (styleIds = []) => {
  return Promise.all(
    styleIds.map((styleId) =>
      request
        .get(`/gaby/styles/${styleId}`)
        .then((r) => r.data)
        .catch(ServerErrorHandler)
    )
  );
};

const fetchDocument = (documentId, selectedOrganization) => {
  return request.get(`/gaby/documents?superId=${documentId}&organization=${selectedOrganization}&current=true`).then(async (res) => {
    if (res.data.length) {
      return res.data[res.data.length - 1];
    }

    return res;
  });
};

const getActionForStyle = (document, styleId) => {
  let type = 'document';

  if (document?.content?.design) {
    const style = document.content.design.find((s) => s.styleId === styleId);

    if (style) {
      type = style.type;
    }
  }
  switch (type) {
    case 'template':
      return actions.TEMPLATE_ACTION;
    default:
      return actions.DOCUMENT_ACTION;
  }
};

export const CodeBlock = ({ style = {}, onSave = null, onDelete }) => {
  const codeRef = useRef(null);

  const [editMode, setEditMode] = useState(false);
  const [height, setHeight] = useState(0);
  const [content, setContent] = useState(style.content);

  useEffect(() => {
    setContent(style.content);
    setEditMode(false);
  }, [style.content]);

  useEffect(() => {
    if (!editMode) {
      Prism.highlightAll();
    }
  }, [editMode]);

  const handleClickEdit = () => {
    // Get the height of the box so we can match the textareas height.
    setHeight(codeRef.current.getBoundingClientRect().height);
    setEditMode(true);
  };

  const handleSave = () => {
    onSave(style, content);
  };

  const handleOnChangeContent = (e) => {
    setContent(e.target.value);
  };

  return (
    <div className="card mb-3">
      <div className="card-body position-relative">
        {editMode ? (
          <>
            <textarea style={{ height: `${height}px` }} className="bg-light w-100 border-0 resize-none" value={content} onChange={handleOnChangeContent} />
            <button className="btn btn-secondary btn-sm position-absolute top-1 end-1" onClick={handleSave}>
              <i className="fa-regular fa-floppy-disk" />
            </button>
          </>
        ) : (
          <>
            <pre className="bg-light">
              <code className="language-css" ref={codeRef}>
                {style.content}
              </code>
            </pre>
            <button className="btn btn-secondary btn-sm position-absolute top-1 end-1" onClick={handleClickEdit}>
              <i className="fa-regular fa-pen" />
            </button>
            {onDelete && (
              <button className="btn btn-secondary btn-sm position-absolute top-1 end-4" onClick={onDelete}>
                <i className="fa-regular fa-trash" />
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const ProjectCSS = ({ documentId = null }) => {
  const [styles, setStyles] = useState(null);
  const [document, setDocument] = useState(null);
  const { selectedOrganization } = useContext(UserContext);

  useEffect(() => {
    if (styles) {
      Prism.highlightAll();
    }
  }, [styles]);

  useEffect(() => {
    const fetchDoc = async () => {
      const doc = await fetchDocument(documentId, selectedOrganization);
      setDocument(doc);

      const styleIds = doc.content.design.map((d) => d.styleId);
      const styles = await fetchStyles(styleIds);

      setStyles(styles);
    };

    fetchDoc();
  }, [documentId, selectedOrganization]);

  const handleSaveStyle = async (oldStyle = {}, newContent = '') => {
    const newStyle = {
      ...oldStyle,
      id: uuidv4(),
      content: newContent,
    };

    request
      .post(`/gaby/styles`, newStyle)
      .then((res) => {
        return request
          .post(`/gaby/documents/${documentId}/actions?compileSass=true`, {
            action: getActionForStyle(document, oldStyle.id),
            revisionId: oldStyle.id,
            data: {
              styleId: newStyle.id,
            },
          })
          .then(() => {
            return res;
          });
      })
      .then((res) => {
        const updatesStyles = styles.map((style) => {
          if (style.id === oldStyle.id) {
            return { ...style, id: res.data.id, content: res.data.content };
          }
          return style;
        });

        setStyles(updatesStyles);
        return fetchDocument(documentId, selectedOrganization);
      })
      .then((doc) => {
        setDocument(doc);
      })
      .catch(ServerErrorHandler);
  };

  if (!styles || !document) {
    return <Spinner />;
  }

  return (
    <>
      {styles.map((style, index) => {
        const design = document.content.design.find((d) => d.styleId === style.id);
        if (!design) return null;
        return (
          <React.Fragment key={index}>
            <div className="fw-bold mt-4 mb-2">{getDetails(design)}</div>
            <CodeBlock style={style} key={index} onSave={handleSaveStyle} />
          </React.Fragment>
        );
      })}
    </>
  );
};

const getDetails = (design) => {
  switch (design.type) {
    case 'document':
      return `${design.type} (${design.templateSuperId}) `;
    case 'template':
      return `${design.type} (.xrp-template-${design.templateSuperId})`;
    case 'graphical-variables':
      return design.type;
    case 'page-variables':
      return `${design.type} (#id-${design.pageId})`;
    default:
      return design.type;
  }
};

export default ProjectCSS;
