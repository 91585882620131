import { UserContext } from 'app/state/contexts/UserContext';
import { useContext, useState } from 'react';
import { i18n } from 'app/utils/i18n';
import Tippy from '@tippyjs/react';
export const CommentControls = ({ comment, actionHandler }) => {
  const { user } = useContext(UserContext);
  const isOwner = comment?.user.id === user.id;
  const [areYouSure, setAreYouSure] = useState(false);
  return (
    <div className="comment--controls">
      {isOwner && !comment.closedAt && (
        <div className="comment--controls--item" onClick={(e) => actionHandler('edit', e)}>
          <i className="fa-regular fa-pen"></i>
          <p>{i18n('comments.edit')}</p>
        </div>
      )}
      {!comment.citationDeletedAt && (
        <div className="comment--controls--item" onClick={(e) => actionHandler(comment?.closedAt ? 'open' : 'close', e)}>
          <i className={`fa-regular ${comment?.closedAt ? 'fa-folder-open' : 'fa-box-archive'}`}></i>
          <p>{i18n(`common.${comment?.closedAt ? 'open' : 'close'}`)}</p>
        </div>
      )}
      {isOwner && (
        <Tippy
          disabled={comment?.responses?.length === 0 || comment?.onType === 'comment'}
          content={<p className="gaby-tooltip arrow-bottom">{i18n('comments.delete-tooltip')}</p>}
        >
          <div
            className={`comment--controls--item ${comment?.responses?.length > 0 ? 'disabled' : ''}`}
            onClick={(e) => {
              if (comment?.responses?.length > 0) return;
              e.stopPropagation();
              setAreYouSure(true);
            }}
          >
            <i className="fa-solid fa-trash-can"></i>
            <p>{i18n('common.delete')}</p>
          </div>
        </Tippy>
      )}
      {areYouSure && (
        <div className="comment--controls--confirm">
          <p>{i18n('comments.delete-confirm')}</p>
          <div className="comment--controls--inner">
            <button className="comment--confirm--button" onClick={(e) => actionHandler('delete', e)}>
              <i className="fa-solid fa-trash-can"></i>
              <p>{i18n('common.delete')}</p>
            </button>
            <button
              className="not-a-button"
              onClick={(e) => {
                e.stopPropagation();
                setAreYouSure(false);
              }}
            >
              {i18n('common.button.cancel')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
