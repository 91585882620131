import { useState, useEffect, useContext } from 'react';
import { useEditorSelector } from 'app/state/contexts/EditorContext';
import { useSlate } from 'slate-react';
import { SidebarContext } from 'app/state/contexts/SidebarContext';
import { insertStyledParagraph } from 'app/slate/extensions/styled-paragraph';
import { v4 as uuidv4 } from 'uuid';
export const AddStyledParagraph = ({ element }) => {
  const selectionId = useEditorSelector((editor) => editor.selectionId);
  const { setStyledParagraph, setIsSidebarOpen } = useContext(SidebarContext);
  const [show, setShow] = useState(true);
  const editor = useSlate();
  const handleClick = (e) => {
    const styledParagraphId = uuidv4();
    insertStyledParagraph(editor, styledParagraphId);
    e.preventDefault();
    setStyledParagraph({ uuid: styledParagraphId });
    setIsSidebarOpen(6);
  };

  useEffect(() => {
    setShow(selectionId && selectionId === element.uuid);
  }, [element.uuid, selectionId]);
  if (!show) {
    return null;
  }
  return (
    <div className="styled-paragraph--add-paragraph">
      <i onMouseDown={handleClick} className="fa-regular fa-rectangle-list" />
    </div>
  );
};
