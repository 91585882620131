import { useState } from 'react';
import { withHistory } from 'slate-history';
import { v4 as uuidv4 } from 'uuid';
import { withReact } from 'slate-react';
import { createEditor, Transforms, Node, Range, Editor as SlateEditor } from 'slate';
import withDefaultRenders from 'app/slate/extensions/default-renderers';
import { compose } from 'ramda';
import { useEditorSelector } from 'app/state/contexts/EditorContext';
import { useDecorateComments } from 'app/slate/components/Decorator';
import { useComments } from 'app/api/useComments';

export const useEditor = (extensions, readOnly) => {
  const [editor] = useState(() => {
    const withExtensions = compose(...extensions, withDefaultRenders, withHistory, withReact);
    const editor = createEditor();
    editor.deleteFragment = () =>
      SlateEditor.withoutNormalizing(editor, () => {
        Array.from(
          Node.texts(editor, {
            from: editor.selection.anchor.path,
            to: editor.selection.focus.path,
          })
        )
          .reverse()
          .map(([node, path]) =>
            Range.intersection(
              {
                anchor: { path, offset: 0 },
                focus: { path, offset: node.text.length },
              },
              editor.selection
            )
          )
          .forEach((range) => {
            if (Range.isCollapsed(range)) {
              return;
            }
            Transforms.delete(editor, { at: range });
          });
      });

    const { normalizeNode } = editor;
    editor.normalizeNode = (entry) => {
      const [node] = entry;
      if (!SlateEditor.isEditor(node)) {
        normalizeNode(entry);
        return;
      }

      const descendants = Node.descendants(node);

      const used = new Set();
      for (const [_node, _path] of descendants) {
        if (!_node.uuid) {
          continue;
        }
        if (used.has(_node.uuid)) {
          Transforms.setNodes(
            editor,
            {
              uuid: uuidv4(),
            },
            {
              at: _path,
            }
          );
        }
        used.add(_node.uuid);
      }
    };

    preventDeletionOverMultiplePages(editor);

    const customEditor = withExtensions(editor);

    // Paste HTML as Text into document
    customEditor.insertData = (data) => {
      if (data) {
        const html = data.getData('text/html');
        if (html) {
          // TODO: Hantera genom att behålla utvalda element och formatering
          const text = data.getData('text/plain');

          customEditor.insertText(text);
          return;
        }
      }

      customEditor.insertData(data);
    };
    return customEditor;
  }, [readOnly]); // eslint-disable-line react-hooks/exhaustive-deps
  return editor;
};

const preventDeletionOverMultiplePages = (editor) => {
  const { insertText, deleteFragment, insertBreak } = editor;

  editor.insertText = (entry) => {
    if (isMultiPageSelection(editor)) {
      console.log('Text insertion prevented, as text selection spans multiple pages');
    } else {
      insertText(entry);
    }
  };

  editor.deleteFragment = (entry) => {
    if (isMultiPageSelection(editor)) {
      console.log('Text deletion prevented, as text selection spans multiple pages');
    } else {
      deleteFragment(entry);
    }
  };

  editor.insertBreak = (entry) => {
    if (isMultiPageSelection(editor)) {
      console.log('Line break prevented, as text selection spans multiple pages');
    } else {
      insertBreak(entry);
    }
  };
};

export const useCheckCitationDelete = (editor) => {
  const section = useEditorSelector((editor) => editor?.section);
  const decorate = useDecorateComments(editor, section?.superId);
  const { deleteCommentConnection } = useComments(section?.superId);

  const { deleteBackward, deleteForward } = editor;
  editor.deleteBackward = (unit) => {
    if (editor.selection) {
      const { anchor } = editor.selection;

      const [node, path] = SlateEditor.node(editor, anchor.path);

      const decorations = decorate([node, path]);

      const deletionStart = anchor.offset;
      const deletionEnd = anchor.offset + 1;
      const overlapping = decorations.find((decoration) => {
        const { anchor: decAnchor, focus: decFocus } = decoration;
        return deletionStart <= decFocus.offset && deletionEnd > decAnchor.offset;
      });
      if (overlapping) {
        if (overlapping.commentId) {
          deleteCommentConnection(overlapping.commentId);
        }
      }
    }
    deleteBackward(unit);
  };

  editor.deleteForward = (unit) => {
    if (editor.selection) {
      const { anchor } = editor.selection;

      const [node, path] = SlateEditor.node(editor, anchor.path);

      const decorations = decorate([node, path]);

      const deletionStart = anchor.offset;
      const deletionEnd = anchor.offset + 1;
      const overlapping = decorations.some((decoration) => {
        const { anchor: decAnchor, focus: decFocus } = decoration;
        return deletionStart < decFocus.offset && deletionEnd > decAnchor.offset;
      });
      if (overlapping) {
        if (overlapping.commentId) {
          deleteCommentConnection(overlapping.commentId);
        }
      }
    }

    deleteForward(unit);
  };
};

const isMultiPageSelection = (editor) => {
  const anchorPage = editor.selection?.anchor.path[0];
  const focusPage = editor.selection?.focus.path[0];
  return anchorPage !== focusPage;
};
