import React, { useEffect, useRef, useState } from 'react';
import { Transforms } from 'slate';
import { Editable, Slate } from 'slate-react';
import useSelection from 'app/components/editor/useSelection';
import { EditorContext, MAIN_EDITOR_ID, MultiEditorContext, useEditorSelector } from 'app/state/contexts/EditorContext';
import { useEditor } from 'app/components/editor/useEditor';
import root from 'react-shadow';

// Copy of Editor in src/app/components/editor/Editor.js
export const Editor = (props) => {
  const { editorId, children, ...rest } = props;

  return (
    <MultiEditorContext.Provider value={editorId || MAIN_EDITOR_ID}>
      <TemplateEditor {...rest}>{children}</TemplateEditor>
    </MultiEditorContext.Provider>
  );
};

export const TemplateEditor = (props) => {
  const { onChange, readOnly, onInsertTemplate, extensions, shadowedChildren, children, isDirty, doc, section, referenceData, locale } = props;
  const editorContainerRef = useRef();
  const [shadowHost, setShadowHost] = useState(null);
  const editor = useEditor(extensions);
  const selection = useSelection(editor, shadowHost);

  // Get a revision, or if not set, get the current version
  let reduxDocument = useEditorSelector((editor) => editor.pDocument);
  if (!!doc) reduxDocument = doc;

  let reduxSections = useEditorSelector((editor) => editor.section);
  if (!!section) reduxSections = section;

  let reduxReferenceData = useEditorSelector((editor) => editor.references);
  if (!!referenceData) reduxReferenceData = referenceData;

  // TODO: Is this needed? We already have <Slate value={} />
  useEffect(() => {
    if (!!reduxSections) {
      editor.children = reduxSections;
    }
  }, [reduxSections, editor]);

  // TODO: What does this do? Is it even used?
  editor.props = () => {
    const { children, ...passProps } = props;
    return passProps;
  };

  editor.onInsertTemplate = onInsertTemplate;

  const handleChange = (value) => {
    onChange(value, editor);

    if (editor.selection) {
      editor.lastSelection = editor.selection;
    }
  };

  const editorContext = {
    reduxDocument,
    reduxSections,
    references: reduxReferenceData,
    editorContainerRef,
    shadowHost,
    selection,
    isDirty,
    readOnly,
    locale,
  };

  if (reduxSections === undefined || !reduxSections?.length) {
    return null;
  }

  return (
    <div className={`editor-container ${props.className || ''}`} ref={editorContainerRef}>
      <EditorContext.Provider value={editorContext}>
        <Slate editor={editor} value={reduxSections} onChange={handleChange}>
          {props.leftComponent?.(reduxSections)}
          <root.div className="editor-shadow-host" ref={setShadowHost}>
            <Editable
              readOnly={readOnly}
              renderElement={editor.renderElement}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && event.shiftKey) {
                  //Soft break on shift enter
                  event.preventDefault();
                  Transforms.insertText(editor, '\n');
                }
              }}
              renderLeaf={(...args) => editor.renderLeaf(...args)}
              decorate={editor.decorate}
              className="editor"
            />
            {shadowedChildren}
            <style type="text/css">{`
              @media screen {
              .editor {
                position: relative;
                display: grid;
              }
              }
              .editor {
                padding-top: 60px;
                row-gap: 60px;
                z-index: 1;
              }               
              
              .layout-leaf-comment { 
                background: #ffd56e;
              }
              
              .editor-image--container,
              .editor-table--container {
                position: relative;
              }
              .editor-image--container .controls--container,
              .editor-table--container .controls--container {
                position: absolute;
                display: flex;
                flex-direction: row;
                top: 5px;
                right: 5px;
                background: #f2f2f2;
                border-radius: 4px;
                box-shadow: 1px 1px 2px black;
                transition: all 300ms ease-in-out;
                opacity: 0;
                padding: 2px;
              }
              .editor-image--container .controls--container button,
              .editor-table--container .controls--container button {
                background: transparent;
                height: 30px;
                width: 30px;
                border: 0;
                cursor: pointer;
                color: red;
              }
              .editor-image--container:hover .controls--container,
              .editor-table--container:hover .controls--container {
                opacity: 1;
              }
            `}</style>
          </root.div>
          {children}
        </Slate>
      </EditorContext.Provider>
    </div>
  );
};
