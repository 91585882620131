import { useContext } from 'react';
import { EditorContext } from 'app/state/contexts/EditorContext';
import { createPortal } from 'react-dom';

export const Portal = ({ children }) => {
  const { editorContainerRef } = useContext(EditorContext);

  if (!editorContainerRef.current) return null;
  return createPortal(children, editorContainerRef.current);
};
