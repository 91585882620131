import { useCallback, useContext } from 'react';
import { Text, Node } from 'slate';
import { findCitationWithContext } from '../utils';
import { useComments } from 'app/api/useComments';
import { useLocation } from 'react-router';
import { SidebarContext } from 'app/state/contexts/SidebarContext';
export const useDecorateComments = (editor, sectionSuperId) => {
  const { comments } = useComments(sectionSuperId);
  const sidebarContext = useContext(SidebarContext);
  const { pathname } = useLocation();
  return useCallback(
    ([node, path]) => {
      const ranges = [];
      if (pathname.includes('pdf')) {
        return ranges;
      }
      if (!Text.isText(node)) {
        return ranges;
      }
      const parentPath = path.slice(0, -1);
      let parentNode;
      try {
        parentNode = Node.get(editor, parentPath);
      } catch (e) {
        return ranges;
      }
      const parentParagraphId = parentNode.uuid ?? null;

      comments?.forEach((comment) => {
        const { citation, onId, citationDeletedAt, closedAt } = comment;

        if (onId !== parentParagraphId || closedAt) {
          return;
        }

        const text = node.text;
        if (citationDeletedAt !== null) {
          ranges.push({
            anchor: { path: parentPath, offset: 0 },
            focus: { path: parentPath, offset: Node.string(parentNode).length },
            commentId: comment.id,
            activeComment: comment.id === sidebarContext?.activeComment?.id,
          });
          return;
        }
        const occurences = findCitationWithContext(text, citation);
        occurences.forEach((occurence) => {
          ranges.push({
            anchor: { path, offset: occurence.start },
            focus: { path, offset: occurence.end },
            commentId: comment.id,
            activeComment: comment.id === sidebarContext?.activeComment?.id,
          });
        });
      });

      return ranges;
    },
    [comments, editor, pathname, sidebarContext]
  );
};
