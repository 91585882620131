import { Editor as SlateEditor } from 'slate';
import { call, chain, flip, map, nth, pipe, prop, propEq, propIs, reduce, reject, startsWith, uniqBy, uniqWith, intersperse, descend, sort, path } from 'ramda';

/**
 * From a list of slate operations return a new list where
 * we keep only the most specific ones, i.e. the deepest paths.
 */
const mostSpecificUniqOperations = pipe(
  reject(propEq('type', 'set_selection')),
  reject(propEq('type', 'remove_node')),
  sort(descend(path(['path', 'length']))),
  uniqWith((a, b) => startsWith(b.path, a.path))
);

/**
 * Returns a new function f(path) specific for the given section.
 * f will given a path return the node at path in the section.
 */
export const childFinder = (section) => pipe(map(nth), intersperse(prop('children')), reduce(flip(call), section));

/**
 * Given a list of slate operations return the changed node along with all its
 * ancestors but filter the list to only return nodes with uuid's
 */
const nodesFromOperation = (editor) => (operation) => {
  try {
    return Array.from(
      SlateEditor.nodes(editor, {
        at: operation.path,
        match: propIs(String, 'uuid'),
      })
    );
  } catch {
    /**
     * Catch operation paths that does not exist anymore. For example adding new lines to a section
     * will result in them being removed from the editor but not from the operations path. This will
     * catch the out of bounds exception and return an empty set of nodes from this operation.
     */
    return [];
  }
};

/**
 * Return all nodes that should be considered changed given a list of slate operations.
 */
export const changedNodesFromOperations = (editor, operations) =>
  pipe(
    mostSpecificUniqOperations,
    chain(nodesFromOperation(editor)),
    uniqBy((entry) => entry[0].uuid)
  )(operations);

export const navigateTo = (shadowHost, id) => {
  const elementId = `#${id}`;
  const foundElement = shadowHost.shadowRoot.querySelector(elementId);
  const y = foundElement?.getBoundingClientRect().top + window.scrollY - 160;
  window.scrollTo({ top: y, behavior: 'smooth' });
};

export const findCitationWithContext = (text, citation) => {
  const ranges = [];
  const escapedCitation = citation.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  let regex;
  if (citation.includes(' ')) {
    const flexibleCitation = escapedCitation.replace(/\s+/g, '\\s+');
    regex = new RegExp(flexibleCitation, 'gi');
  } else {
    regex = new RegExp(`\\b${escapedCitation}\\b`, 'gi');
  }

  let match;
  while ((match = regex.exec(text)) !== null) {
    ranges.push({ start: match.index, end: match.index + match[0].length });
  }
  return ranges;
};
