import * as DOMPurify from 'dompurify';
import { useMemo } from 'react';

const DataImage = ({ fileType, fileContent, style, filename }) => {
  const cleanData = useMemo(() => {
    if (fileType === 'image/svg+xml') {
      let sanitized = DOMPurify.sanitize(atob(fileContent));
      // Remove width and height attributes
      let svgTag = sanitized.substring(0, sanitized.indexOf('>') + 1);
      let svgContent = sanitized.substring(sanitized.indexOf('>') + 1);
      svgTag = svgTag?.replace(/width="[^"]*"/, 'width="100%"')?.replace(/height="[^"]*"/, 'height="auto"');

      // Add viewBox if missing to enable responsiveness
      if (!svgTag.includes('viewBox')) {
        svgTag = svgTag.replace('<svg ', '<svg viewBox="0 0 24 24" ');
      }
      return `data:image/svg+xml;base64,${btoa(svgTag + svgContent)}`;
    } else {
      return `data:${fileType};base64,${fileContent}`;
    }
  }, [fileContent, fileType]);

  return <img src={cleanData} style={style} alt={filename} />;
};

export default DataImage;
