import { Element, Transforms, Editor } from 'slate';
import { isSimpleBlockActive } from 'app/slate/functions';

const isStyledParagraph = (node) => Element.isElement(node) && node.type === 'styled-paragraph';

const withStyledParagraph =
  (options = {}) =>
  (editor) => {
    const { renderElement, isBlock, normalizeNode } = editor;

    editor.isBlock = (element) => isStyledParagraph(element) || isBlock(element);

    editor.renderElement = (props) => {
      if (isStyledParagraph(props.element)) {
        return <StyledParagraphElement {...props} />;
      } else {
        return renderElement(props);
      }
    };

    editor.normalizeNode = ([node, path]) => {
      normalizeNode([node, path]);
    };

    return editor;
  };

export const StyledParagraphElement = ({ attributes, children, element }) => {
  return (
    <div {...attributes} className={`xrp-super-id-${element.id}`}>
      {children}
    </div>
  );
};

export const insertStyledParagraph = (editor, uuid) => {
  const isActive = isSimpleBlockActive(editor, 'styled-paragraph');

  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'styled-paragraph',
    split: true,
  });

  if (!editor.selection) return;

  Transforms.setNodes(
    editor,
    { type: 'paragraph' },
    {
      at: editor.lastSelection,
    }
  );

  if (!isActive) {
    const block = { type: 'styled-paragraph', children: [], id: uuid };
    Transforms.wrapNodes(editor, block);
  }
};

export const unWrapStyledParagraph = (editor, id) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === 'styled-paragraph' && n.id === id,
  });

  if (!match) return; // No matching styled paragraph found

  const [, path] = match;
  Transforms.unwrapNodes(editor, {
    at: path,
    match: (n) => n.type === 'styled-paragraph', // Ensure we only unwrap styled paragraphs
  });
};

export const isInsideStyledParagraph = (editor) => {
  if (!editor.selection) return null;

  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === 'styled-paragraph',
  });

  return match ? match[0] : null; // Return the styled paragraph node if found
};

export default withStyledParagraph;
